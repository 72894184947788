import { useEffect, useState, useRef } from "react";
import { CalendarIcon } from "@radix-ui/react-icons";
import { format } from "date-fns";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

interface DatePickerProps {
  currentValue: Date | undefined;
  onDateChange: (date: Date | undefined, identifier: string) => void;
  identifier: string;
}

export function DatePicker({
  currentValue,
  onDateChange,
  identifier,
}: DatePickerProps) {
  const [date, setDate] = useState<Date | undefined>(currentValue);
  const isInternalChange = useRef(false);

  useEffect(() => {
    if (!isInternalChange.current && date !== currentValue) {
      setDate(currentValue);
    }
    isInternalChange.current = false;
  }, [currentValue]);

  useEffect(() => {
    if (isInternalChange.current) {
      onDateChange(date, identifier);
    }
  }, [date, onDateChange, identifier]);

  const handleDateChange = (newDate: Date | undefined) => {
    isInternalChange.current = true;
    setDate(newDate);
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[240px] justify-start text-left font-normal",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, "PPP") : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={handleDateChange}
          initialFocus
          defaultMonth={date || new Date()} // Show the current date if no date is selected
        />
      </PopoverContent>
    </Popover>
  );
}
