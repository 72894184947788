"use client";

import { useState, useEffect, useRef } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { DatePicker } from "@/components/DatePicker";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert"; // Import the Alert component
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ArrowUpDown, ArrowUp, ArrowDown } from "lucide-react";

type AccountingEntry = {
  date: string;
  liability: number;
  payable: number;
  assets: number;
  receivable: number;
  equity: number;
  revenue: number;
};

type bodyData = {
  user: string;
  fromDate: string;
  toDate: string;
};

type SortConfig = {
  key: keyof AccountingEntry;
  direction: "asc" | "desc";
};

export default function InputForm() {
  const [activeTab, setActiveTab] = useState("form");
  const [visibleTabs, setVisibleTabs] = useState({
    form: true,
    table: false,
  });
  const token = localStorage.getItem("token");
  const LocalStorageData = token ? JSON.parse(token) : null;
  const [currentWindowWidth, setCurrentWindowWidth] = useState(
    window.innerWidth
  );
  const [entries, setEntries] = useState<AccountingEntry[]>([]);
  const [formData, setFormData] = useState({
    liability: "",
    payable: "",
    assets: "",
    receivable: "",
    equity: "",
    revenue: "",
  });
  const [dateRange, setDateRange] = useState<{ from?: Date; to?: Date }>({
    from: new Date(new Date().setDate(new Date().getDate() - 7)),
    to: new Date(),
  });
  const [error, setError] = useState<string | null>(null);
  const handleDateChange = (date: Date | undefined, identifier: string) => {
    setDateRange((prev) => {
      const newDateRange = { ...prev, [identifier]: date };
      if (
        newDateRange.from &&
        newDateRange.to &&
        newDateRange.from > newDateRange.to
      ) {
        setError("The 'to' date cannot be before the 'from' date.");
      } else {
        setError(null);
      }
      return newDateRange;
    });
  };

  const clearDateRange = () => {
    setDateRange({ from: undefined, to: undefined });
    setError(null);
  };

  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: "date",
    direction: "desc",
  });
  const tableRef = useRef<HTMLDivElement>(null);

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const apikey = LocalStorageData?.apiKey;
    const email = LocalStorageData?.Details.email;

    if (!LocalStorageData || !LocalStorageData.Details) {
      console.error(
        "LocalStorageData or LocalStorageData.Details is undefined"
      );
      return;
    }

    const fromDate = formatDate(dateRange.from ?? new Date());
    const toDate = formatDate(dateRange.to ?? new Date());
    const bodyDATA: bodyData = {
      user: email,
      fromDate: fromDate,
      toDate: toDate,
    };

    const headers = new Headers();
    headers.append("Authorization", `Bearer ${apikey}`);
    headers.append("Content-Type", "application/json");

    fetch(`${import.meta.env.VITE_BASE_URL}/getaccountentries`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(bodyDATA),
    })
      .then((res) => res.json())
      .then((data) => {
        setEntries(data.data);
      });
  }, [dateRange]);

  useEffect(() => {
    const handleResize = () => {
      setCurrentWindowWidth(window.innerWidth);

      setVisibleTabs({
        form: window.innerWidth >= 768 ? true : activeTab === "form",
        table: window.innerWidth >= 768 ? true : activeTab === "table",
      });
    };

    // Initial call to set visibility
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [activeTab]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newDate = formatDate(new Date());
    const newEntry = {
      date: newDate,
      ...Object.fromEntries(
        Object.entries(formData).map(([key, value]) => [
          key,
          parseFloat(value) || 0,
        ])
      ),
    } as AccountingEntry;

    if (
      Object.entries(newEntry).every(
        ([key, value]) => key === "date" || value === 0
      )
    ) {
      return;
    }

    const apikey = LocalStorageData?.apiKey;
    const newFromData = formatDate(dateRange.from ?? new Date());
    const newToData = formatDate(dateRange.to ?? new Date());
    const bodyDATA: bodyData = {
      user: LocalStorageData?.Details.email,
      fromDate: newFromData,
      toDate: newToData,
    };

    fetch(`${import.meta.env.VITE_BASE_URL}/insertaccountentries`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${apikey}`,
      },
      body: JSON.stringify({ ...newEntry, ...bodyDATA }),
    })
      .then((res) => res.json())
      .then(() => {
        setEntries([newEntry, ...entries]);
        setFormData({
          liability: "",
          payable: "",
          assets: "",
          receivable: "",
          equity: "",
          revenue: "",
        });
      });
  };

  const handleSort = (key: keyof AccountingEntry) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const filteredEntries = entries.filter((entry) => {
    const entryDate = formatDate(new Date(entry.date));
    const fromDate = dateRange.from ? formatDate(dateRange.from) : null;
    const toDate = dateRange.to ? formatDate(dateRange.to) : null;
    return (
      (!fromDate || entryDate >= fromDate) && (!toDate || entryDate <= toDate)
    );
  });

  const sortedEntries = [...filteredEntries].sort((a, b) => {
    if (sortConfig.key === "date") {
      return sortConfig.direction === "asc"
        ? new Date(a.date).getTime() - new Date(b.date).getTime()
        : new Date(b.date).getTime() - new Date(a.date).getTime();
    }
    if (a[sortConfig.key] < b[sortConfig.key])
      return sortConfig.direction === "asc" ? -1 : 1;
    if (a[sortConfig.key] > b[sortConfig.key])
      return sortConfig.direction === "asc" ? 1 : -1;
    return 0;
  });

  const SortIcon = ({ columnKey }: { columnKey: keyof AccountingEntry }) => {
    if (sortConfig.key === columnKey) {
      return sortConfig.direction === "asc" ? (
        <ArrowUp className="ml-2 h-4 w-4" />
      ) : (
        <ArrowDown className="ml-2 h-4 w-4" />
      );
    }
    return <ArrowUpDown className="ml-2 h-4 w-4" />;
  };

  const handleTabsChange = (value: string) => {
    setActiveTab(value);
    setVisibleTabs({
      form: value === "form",
      table: value === "table",
    });
  };

  return (
    <div className="mx-auto p-4 h-screen flex flex-col">
      {currentWindowWidth < 768 && (
        <Tabs
          onValueChange={handleTabsChange}
          defaultValue={activeTab}
          className="w-full "
        >
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="form">Form</TabsTrigger>
            <TabsTrigger value="table">Table</TabsTrigger>
          </TabsList>
        </Tabs>
      )}

      <div className="flex flex-col md:flex-row gap-8 flex-grow overflow-hidden">
        {visibleTabs.form && (
          <div className="w-full p-2 md:w-1/3 overflow-y-auto flex items-center justify-center border-r border-gray-200 pr-8">
            <form onSubmit={handleSubmit} className="space-y-4 w-full">
              <h2 className="text-2xl font-bold mb-4">Enter Accounting Data</h2>
              {Object.keys(formData).map((field) => (
                <div key={field}>
                  <Label htmlFor={field}>
                    {field.charAt(0).toUpperCase() + field.slice(1)}
                  </Label>
                  <Input
                    type="number"
                    id={field}
                    name={field}
                    value={formData[field as keyof typeof formData]}
                    onChange={handleInputChange}
                    placeholder={`Enter ${field}`}
                  />
                </div>
              ))}
              <Button type="submit" className="w-full">
                Submit
              </Button>
            </form>
          </div>
        )}
        {visibleTabs.table && (
          <div className="w-full md:w-2/3 flex flex-col overflow-hidden">
            <h2 className="text-2xl font-bold mb-4">Accounting Entries</h2>
            <div className="flex flex-col md:flex-row gap-4 mb-4">
              <div>
                <Label htmlFor="fromDate">From Date</Label>
                <div>
                  <DatePicker
                    currentValue={dateRange.from}
                    onDateChange={handleDateChange}
                    identifier="from"
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="toDate">To Date</Label>
                <div>
                  <DatePicker
                    currentValue={dateRange.to}
                    onDateChange={handleDateChange}
                    identifier="to"
                  />
                </div>
              </div>
              <div>
                <Label>Clear Date Range</Label>
                <div>
                  <Button onClick={clearDateRange}>Clear</Button>
                </div>
              </div>
            </div>
            {error && (
              <Alert variant="destructive">
                <AlertTitle>Heads up!</AlertTitle>
                <AlertDescription>
                  The From date cannot be after the To date.
                </AlertDescription>
              </Alert>
            )}
            <div ref={tableRef} className="overflow-hidden flex-grow">
              <div className="h-full flex flex-col">
                <div
                  style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
                  className="bg-background overflow-auto"
                >
                  <Table>
                    <TableHeader className="sticky top-0 bg-background">
                      <TableRow>
                        {[
                          "date",
                          "liability",
                          "payable",
                          "assets",
                          "receivable",
                          "equity",
                          "revenue",
                        ].map((key) => (
                          <TableHead
                            key={key}
                            className="cursor-pointer"
                            onClick={() =>
                              handleSort(key as keyof AccountingEntry)
                            }
                          >
                            <div className="flex items-center">
                              {key.charAt(0).toUpperCase() + key.slice(1)}
                              <SortIcon
                                columnKey={key as keyof AccountingEntry}
                              />
                            </div>
                          </TableHead>
                        ))}
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {sortedEntries.map((entry, index) => (
                        <TableRow
                          key={index}
                          className={index % 2 === 0 ? "bg-muted" : ""}
                        >
                          <TableCell>{entry.date.split("T")[0]}</TableCell>
                          <TableCell>{entry.liability}</TableCell>
                          <TableCell>{entry.payable}</TableCell>
                          <TableCell>{entry.assets}</TableCell>
                          <TableCell>{entry.receivable}</TableCell>
                          <TableCell>{entry.equity}</TableCell>
                          <TableCell>{entry.revenue}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
