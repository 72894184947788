import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Dashboard } from "./Pages/Dashboard";
// import ErrorPage from "./Pages/ErrorPage.tsx";
import InputForm from "./Pages/InputForm";
import InputForm2 from "./Pages/InputForm2";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Login } from "./Pages/Login";
import { Register } from "./Pages/Register";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import { ThemeProvider } from "./components/ui/theme-provider";
import { TooltipProvider } from "./components/ui/tooltip";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    // errorElement: <ErrorPage />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    // errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login />,
    // errorElement: <ErrorPage />,
  },
  {
    path: "/register",
    element: <Register />,
    // errorElement: <ErrorPage />,
  },
  {
    path: "/datainput",
    element: <InputForm />,
    // errorElement: <ErrorPage />,
  },
  {
    path: "/input2",
    element: <InputForm2 />,
    // errorElement: <ErrorPage />,
  },
]);
const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
      <TooltipProvider>
        <RouterProvider router={router} />
      </TooltipProvider>
    </ThemeProvider>
  </React.StrictMode>
);
